.page {
  width: 100%;
  height: 100%;
  position: absolute;
}

.bottom-tag-html {
  margin-left: -20px;
}

.container {
  width: 100%;
  will-change: contents;
  height: 90%;
  min-height: 566px;
  position: absolute;
  top: 5%;
  margin: 0 auto;
  z-index: 1;
  transform-style: preserve-3d;
  opacity: 0;
}

.contact-page,
.about-page,
.projects-page {
  opacity: 0;
  animation: fadeIn 1s forwards;
  animation-delay: 1s;

  h1 {
    font-size: 53px;
    font-family: "Coolvetica";
    color: #ffc000;
    font-weight: 400;
    margin-top: 0;
    position: relative;
    margin-bottom: 40px;
    left: -10px;

    &:before {
      content: '"';
      font-family: "La Belle Aurore", cursive;
      color: #ffc000;
      font-size: 40px;
      position: absolute;
      margin-top: 0px;
      left: -15px;
      opacity: 0.6;
    }

    &:after {
      content: '"';
      font-family: "La Belle Aurore", cursive;
      color: #ffc000;
      font-size: 40px;
      position: absolute;
      margin-top: 10px;
      margin-left: 5px;
      opacity: 0.6;
    }
  }
  p {
    font-size: 13px;
    color: #fff;
    font-family: sans-serif;
    font-weight: 300;
    max-width: fit-content;
    animation: pulse 1s;
    &:nth-of-type(1) {
      animation-delay: 1.1s;
    }
    &:nth-of-type(2) {
      animation-delay: 1.2s;
    }
    &:nth-of-type(3) {
      animation-delay: 1.3s;
    }
  }

  .text-animate-hover {
    &:hover {
      color: #fff;
    }
  }
}
