$primary-color: #ffc000;

@import "animate.css";
@import "~loaders.css/src/animations/line-scale-pulse-out-rapid.scss";

@font-face {
  font-family: "Helvetica Neue";
  src: url("./assets/fonts/helvetica-neu.ttf") format("ttf");
}
@font-face {
  font-family: "La Belle Aurore";
  src: url("./assets/fonts/LaBelleAurore.woff2") format("woff2");
}
@font-face {
  font-family: "Coolvetica";
  src: url("./assets/fonts/CoolveticaRg-Regular.woff2") format("woff2");
}

input,
textarea {
  font-family: "Helvetica Neue";
}

.loader-hidden {
  display: none;
}

.loader-active {
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  top: 0;
  bottom: 0;
  width: 50px;
  height: 50px;
  animation: fadeOut 1s 1s;
  animation-fill-mode: forwards;
}
.home-page {
  .text-zone {
    position: absolute;
    left: 10%;
    top: 30%;
    transform: translateY(-10%);
    width: 50%;
    max-height: 80%;
  }

  h1 {
    // color: #52dee5;
    // font-size: 56px;
    line-height: 53px;
    margin: 0;
    font-family: 'Coolvetica';
    font-weight: 400;

    &:before {
      content: '"';
      font-family: 'La Belle Aurore', cursive;
      color: #ffc000;
      font-size: 40px;
      position: absolute;
      margin-top: 0px;
      left: -15px;
      // opacity: 0.6;
    }

    &:after {
      content: '"';
      font-family: 'La Belle Aurore', cursive;
      color: #ffc000;
      font-size: 40px;
      position: absolute;
      margin-top: 10px;
      margin-left: 5px;
      //   animation: fadeIn 1s 1.7s backwards;
      // opacity: 0.6;
    }

    img {
      margin-left: 20px;
      opacity: 0;
      width: 32px;
      height: auto;
      animation: rotateIn 1s linear both;
      animation-delay: 1.4s;
    }
  }

  h2 {
    // color: #8d8d8d;
    // margin-top: 20px;
    // font-weight: 400;
    // font-size: 11px;
    font-family: sans-serif;
    // letter-spacing: 3px;
    animation: fadeIn 1s 1.8s backwards;
  }

  .flat-button {
    color: #ffc000;
    font-size: 13px;
    font-weight: 400;
    letter-spacing: 4px;
    font-family: sans-serif;
    text-decoration: none;
    padding: 10px 18px;
    border: 1px solid #ffc000;
    margin-top: 25px;
    float: left;
    animation: fadeIn 1s 1.8s backwards;
    white-space: nowrap;

    &:hover {
      background: #ffc000;
      color: #333;
    }
  }
}
.projects-page {
  h1 {
    font-size: 53px;
    font-family: "Coolvetica";
    color: #ffd700;
    font-weight: 400;
    margin-top: 0;
    position: relative;
    margin-bottom: 40px;
    left: -10px;

    &:before {
      content: '"';
      font-family: "La Belle Aurore", cursive;
      color: #ffd700;
      font-size: 40px;
      position: absolute;
      margin-top: 0px;
      left: -15px;
      opacity: 0.6;
    }

    &:after {
      content: '"';
      font-family: "La Belle Aurore", cursive;
      color: #ffd700;
      font-size: 40px;
      position: absolute;
      margin-top: 10px;
      margin-left: 5px;
      opacity: 0.6;
    }
  }

  p {
    font-size: 13px;
    color: #fff;
    font-family: sans-serif;
    font-weight: 300;
    max-width: fit-content;
    animation: pulse 1s;
    &:nth-of-type(1) {
      animation-delay: 1.1s;
    }
    &:nth-of-type(2) {
      animation-delay: 1.2s;
    }
    &:nth-of-type(3) {
      animation-delay: 1.3s;
    }
  }
  .project-item {
    animation: pulse 1s 1s;
    @for $i from 1 through 5 {
      &:nth-of-type(#{$i}) {
        animation-delay: calc(2s + 0.1s * $i);
      }
    }
    .project-title {
      display: flex;
      justify-content: space-between;
    }
    .linkBtn {
      color: #fff;
      background-color: #444;
      white-space: nowrap;
      outline: none;
      border: none;
      text-decoration: none;
      cursor: pointer;
      border-radius: 1rem;
      padding: 0 0;
      font-size: 1rem;
      width: 10%;
      margin: 10px 10px -10px 0;
      // display: flex;
      // align-items: center;
    }
    a {
      all: unset;
    }
  }
  h4 {
    font-size: 13px;
    margin: 0px 0 -10px 0;
    padding: 10px 0px 10px 10px;
  }
  h5 {
    font-size: 12px;
    margin: -5px 0 10px 5px;
    padding-left: 10px;
  }
}

.contact-form {
  width: 50%;
  margin-top: 20px;

  ul {
    padding: 0;
    margin: 0;

    li {
      padding: 0;
      margin: 0;
      list-style: none;
      margin-bottom: 10px;
      opacity: 0;
      overflow: hidden;
      display: block;
      clear: both;
      position: relative;
      animation: fadeInUp 2s 2s;
      animation-fill-mode: forwards;
    }

    li.half {
      width: 49%;
      margin-left: 2%;
      float: left;
      clear: none;

      &:first-child {
        margin-left: 0;
      }
    }
  }

  input[type='text'],
  input[type='email'] {
    width: 100%;
    border: 0;
    background: #115173;
    height: 50px;
    font-size: 16px;
    color: #fff;
    padding: 0 20px;
    box-sizing: border-box;
  }

  textarea {
    width: 100%;
    border: 0;
    background: #115173;
    height: 50px;
    font-size: 16px;
    color: #fff;
    padding: 20px;
    box-sizing: border-box;
    min-height: 150px;
  }

  .flat-button {
    color: #ffd700;
    font-size: 11px;
    letter-spacing: 3px;
    text-decoration: none;
    padding: 8px 10px;
    border: 1px solid #ffd700;
    float: left;
    border-radius: 4px;
    background: 0 0;
    text-transform: uppercase;
    float: right;
    text-align: center;
    margin-right: 10px;
    &:hover {
      background: #ffd700;
      color: #333;
    }
  }
}

.map-wrap {
  background: rgba(8, 253, 216, 0.1);
  float: right;
  width: 53%;
  height: 80%;
  margin-top: 10%;
}

.leaflet-container {
  position: relative;
  width: 100%;
  height: 100%;
  opacity: 0;
  animation: backInRight 1s 1.2s;
  animation-fill-mode: forwards;
}

.info-map {
  position: relative;
  background: #000;
  top: 50px;
  left: 70%;
  z-index: 999999;
  width: 200px;
  padding: 20px;
  color: #fff;
  font-family: 'Helvetica';
  font-size: 17px;
  font-weight: 300;
  opacity: 0;
  animation: fadeIn 1s 1.5s;
  animation-fill-mode: forwards;

  span {
    font-size: 16px;

    span {
      color: #ffd700;
    }
  }
}
